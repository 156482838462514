import './App.css';
import img from './logo.svg';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={img} className="App-logo" alt="Logo OLCZAK.TECH" />
        <p>
        <code><strong>OLCZAK.TECH</strong></code><br />
        ul. Stefana Batorego 18/108<br />
        02-591 Warszawa<br /><br />
        NIP: 9522252573<br />
        REGON: 527829680<br />
        PL 89 1090 1753 0000 0001 5713 9752
        </p>
        <a
          className="App-link"
          href="mailto:hello@olczak.tech"
          target="_blank"
          rel="noopener noreferrer"
        >
          hello@olczak.tech
        </a>
      </header>
    </div>
  );
}

export default App;
